import { Backdrop, Button, CircularProgress, Input, Stepper, IconButton, Step, StepContent, StepLabel, Table, TableHead, TableRow, TableCell, TableBody, Modal } from "@material-ui/core";
import { Box, Checkbox, Flex, Typography } from "venwiz-ui";
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { JobRequestActions } from "container/JobRequests/actions";
import { FORM_DATA_KEY } from "../../jobRequest.constants";
import { StyledBox } from "../Quotation.style";
import { colors } from "Theme";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination } from "@mui/material";

const QuotationComparisonTool = () => {
  const [templateFile, setTemplateFile] = useState<any>()
  const [filledFiles, setFilledFiles] = useState<any>()
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [sheetsToCompare, setSheetsToCompare] = useState<any>()
  const [selectedSheets, setSelectedSheets] = useState<any>([]);
  const [headers, setHeaders] = useState<any>();
  const [vendorFileName, setVendorFileName] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const [quotesTable, setQuotesTable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0); // Assuming API returns total count

  useEffect(() => {
    loadQuotes();
  }, [page, rowsPerPage]);

  const loadQuotes = async () => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    const { quotes, total } = await fetchQuotes(start, end); // API call to fetch quotes
    setQuotesTable(quotes);
    setTotalRows(total);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when changing rows per page
  };

  // Function to open modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };


  const stepsForComparison = [
    "Upload BOQ file", "Select the sheets you want to compare", "Select columns that vendor has to fill",
    "Upload Vendor Quotes", "Subcategory and Pivot Table"];
  const submitTemplateFile = (e: any) => {
    setTemplateFile(e.target.files[0])

  }

  const submitVendorFiles = (e: any) => {
    const files = e.target.files;
    const updatedFiles = Array.from(files).map((file: any) => {
      const timestamp = Date.now(); // Generate the timestamp
      const uniqueFileName = `${file.name.split('.')[0]}_@_${timestamp}.${file.name.split('.').pop()}`; // Append timestamp to filename
      return new File([file], uniqueFileName, { type: file.type });
    });

    setFilledFiles(updatedFiles); // Set the updated files with unique names
  };

  const handleNext = async () => {
    // Assuming API calls are handled with fetch or axios
    switch (activeStep) {
      case 0:
        // Handle file upload API call
        submitTemplateForComparison()
        break;
      case 1:
        const headerInfoArr: any = {}
        Object.keys(selectedSheets).map((sheetName: any) => {
          headerInfoArr[sheetName] = {
            client: sheetsToCompare[sheetName].headerRowValues,
            vendor: [],
            pivot: []
          }
        })
        setHeaders(headerInfoArr)
        break;
      case 3:
        break;
      case 4:
        createComparison()
        break;
      default:
        break;
    }
    setIsLoading(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setSelectedSheets({})
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  };

  const navigateToComparisonPage = (rowId: any) => {
    history.push(`/comparison/${rowId}`);
  };

  const submitTemplateForComparison = () => {
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, templateFile);
    setIsLoading(true)

    dispatch(JobRequestActions.inspectBOQ(formData)
    ).then((data: any) => {
      setSheetsToCompare(data);
      setSelectedSheets({})

    });
  }

  const addHeaderToVendorList = (sheetName: string, clientHeader: string) => {
    let update: any = { ...headers };
    if (!update[sheetName].vendor.includes(clientHeader)) {
      update[sheetName].vendor.push(clientHeader)
    } else {
      update[sheetName].vendor.splice(update[sheetName].vendor.indexOf(clientHeader), 1)
    }
    update[sheetName].vendor = [...Array.from(new Set(update[sheetName].vendor))]
    setHeaders(update);
  }

  const addSheetToCategory = (sheetName: string) => {
    let update: any = { ...headers };
    if (!update[sheetName].subcategory) {
      update[sheetName].subcategory = true
    } else {
      update[sheetName].subcategory = !update[sheetName].subcategory;
    }
    setHeaders(update);
  }

  const addColToPivot = (sheetName: string, colName: string) => {
    let update: any = { ...headers };
    if (!update[sheetName].pivot.includes(colName)) {
      update[sheetName].pivot.push(colName)
    } else {
      update[sheetName].pivot.splice(update[sheetName].vendor.indexOf(colName), 1)
    }
    setHeaders(update);
  }


  const stepDisable = () => {
    if (activeStep === 0 && !templateFile) {
      return true;
    }
    else if (activeStep === 1 && !Object.keys(selectedSheets).length) {
      return true;
    }
    else if (activeStep === 2 && !Object.values(headers).map((v: any) => v.vendor)[0].length) {
      return true;
    }
    else if (activeStep === 3) {
      if (!filledFiles?.length) {
        return true;
      } else if (!!filledFiles?.length && Array.from(filledFiles).map((f: any) => !!vendorFileName[f.name.replace(".xlsx", '')]).filter(Boolean).length !== filledFiles?.length) {
        return true;
      }
    }
    return false;
  }
  const createComparison = () => {
    // clearModal()

    const payloadForComparison: any = {
      templateFile, filledFiles,
      sheets: []
    }
    Object.keys(headers).map((sheetName: any) => {
      payloadForComparison.sheets.push({
        name: sheetName,
        vendorToFill: headers[sheetName].vendor,
        headerRow: sheetsToCompare[sheetName].headerRow,
        vendorFileName: vendorFileName,
        subcategory: headers[sheetName].subcategory,
        pivot: headers[sheetName].pivot
      })
    })
    const formData = new FormData();
    ([templateFile]).concat(...filledFiles).forEach(file => {
      formData.append(FORM_DATA_KEY, file);
    })

    formData.append("sheets", JSON.stringify(payloadForComparison["sheets"]));
    dispatch(JobRequestActions.quoteComparison(formData, { sheets: payloadForComparison["sheets"] })
    ).then((data: any) => {
      setIsModalOpen(false); // Close modal
      setIsLoading(true);
      clearModal();
      setTimeout(async () => {
        setIsLoading(false); // Hide loading after 10 seconds
        const { quotes, total } = await fetchQuotes(0, rowsPerPage); // API call to fetch quotes
        setQuotesTable(quotes);
        setTotalRows(total);
      }, 5000);
    })
  }

  const clearModal = () => {
    setFilledFiles({})
    setTemplateFile({})
    setSheetsToCompare({})
    setActiveStep(-1)
    setSelectedSheets({})
  }
  const handleVendorNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedName = { ...vendorFileName };
    updatedName[filledFiles[index].name.replace(".xlsx", '')] = e.target.value;
    setVendorFileName(updatedName);
  };

  const changeHeaderRow = (e: any, sheetName: string) => {
    const sheetData = JSON.parse(JSON.stringify(sheetsToCompare));
    sheetData[sheetName].headerRow = e.target.value;
    const formData = new FormData();
    formData.append(FORM_DATA_KEY, templateFile);
    formData.append("data", JSON.stringify({
      sheetName,
      headerRow: e.target.value
    }));
    dispatch(JobRequestActions.getColumns(formData)
    ).then((data: any) => {
      sheetData[sheetName].headerRowValues = data[0];
      setSheetsToCompare(sheetData);
    });
    setSheetsToCompare(sheetData);
  }

  useEffect(() => {
    dispatch(JobRequestActions.getComparisonFile({ start: 0, end: rowsPerPage })
    ).then((data: any) => {
      setQuotesTable(data.quotes);
      setTotalRows(data.total);
    });
  }, [])

  const fetchQuotes = (start: number, end: number) => {
    return dispatch(JobRequestActions.getComparisonFile({ start, end })
    ).then((data: any) => {
      return data
    });
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledBox>
        < StyledBox >
          {/* Button to open modal */}
          < Button variant="contained" color="primary" onClick={handleOpenModal} >
            Create a new comparison
          </Button >
          < Modal open={isModalOpen} onClose={(event, reason) => {
            // Prevent closing on clicking the backdrop or pressing the 'Escape' key
            if (reason === "backdropClick") {
              return;
            }
            setIsModalOpen(false);
          }}>
            <Box
              style={{
                width: "800px",
                margin: "100px auto",
                backgroundColor: "white",
                maxHeight: "80vh", // Limit height to 80% of the viewport
                overflowY: "auto", // Enable vertical scrolling
                padding: "20px",
                textAlign: "left",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                position: "relative"

              }}
            >
              <IconButton
                onClick={() => setIsModalOpen(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
              >
                <CloseIcon />
              </IconButton>

              <><Box mt={2}>
                <Stepper activeStep={activeStep} orientation="vertical" style={{ width: "100%" }} >
                  {stepsForComparison.map((label) => (
                    <Step key={label} style={{ width: "100%" }}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <Box>
                          {activeStep === 0 && (
                            <Box style={{ width: "100%", padding: "30px" }}>
                              <Button variant="contained" component="label">
                                Upload File
                                <input type="file" hidden onChange={submitTemplateFile} />
                              </Button>
                              {templateFile && <Typography>{templateFile.name}</Typography>}
                            </Box>
                          )}
                          {activeStep === 1 && (
                            <Box>
                              {!!sheetsToCompare && Object.keys(sheetsToCompare)?.map((sheetName, index) => (
                                <Flex key={index} style={{ justifyContent: "space-between", width: "70%" }}>
                                  <Box style={{ display: "flex", width: "200px" }}>
                                    <Checkbox
                                      // checked={selectedOptions.includes(option)}
                                      onChange={() => {
                                        if (Object.keys(selectedSheets).includes(sheetName)) {
                                          setSelectedSheets({ ...selectedSheets, [sheetName]: undefined });
                                        } else {
                                          setSelectedSheets({ ...selectedSheets, [sheetName]: sheetsToCompare[sheetName].headerRow });
                                        }
                                      }} />
                                    <Typography>{sheetName}</Typography>
                                  </Box>
                                  <Input
                                    disabled={!Object.keys(selectedSheets).includes(sheetName)}
                                    style={{ padding: "0", margin: "0px 30px", width: "200px" }} value={sheetsToCompare[sheetName].headerRow} onChange={(e: any) => changeHeaderRow(e, sheetName)}></Input>
                                </Flex>
                              ))}

                            </Box>
                          )}
                          {activeStep === 2 && (
                            <Box>
                              {!!headers && Object.keys(headers)?.map((sheetName, index) => (
                                <Flex key={index} style={{ justifyContent: "space-between", width: "100%" }}>
                                  <Box >
                                    <><Typography style={{ fontSize: "12px" }}>Sheet name: {sheetName}</Typography>
                                      {headers[sheetName]?.client?.map((clientHeader: any) => (
                                        <Box style={{ display: "flex", padding: "7px", fontSize: "10px" }}>
                                          <Box style={{ display: "flex", width: "95%", lineHeight: "14px", height: "14px" }}>
                                            <Checkbox
                                              checked={headers[sheetName]?.vendor.includes(clientHeader)}
                                              onChange={() => { addHeaderToVendorList(sheetName, clientHeader) }} />
                                            <Typography>{clientHeader}</Typography>
                                          </Box>
                                          {/* <MdDelete style={{ cursor: "pointer" }} color="red" size={20}
                                    onClick={() => onDelete(sheetName, clientHeader)} /> */}
                                        </Box>
                                      ))}
                                    </>
                                  </Box>
                                </Flex>
                              ))}

                            </Box>
                          )}
                          {activeStep === 3 && (
                            <Box style={{ width: "100%", padding: "30px" }}>
                              <Button variant="contained" component="label">
                                Upload Vendor Offers
                                <input type="file" multiple={true} hidden onChange={submitVendorFiles} />
                              </Button>
                              {!!filledFiles?.length && <Typography>No. of Vendor Quotes uploaded: {filledFiles?.length}</Typography>
                                &&
                                <Box padding={"10px"}>
                                  <Typography padding={"10px"}> Please enter vendor names corresponding to files: </Typography>
                                  {Array.from(filledFiles)?.map((file: any, index: number) => (
                                    <Flex padding={"10px"}>
                                      <Typography>{file.name.split("_@_")[0]}</Typography>
                                      <Input
                                        style={{ padding: "0", margin: "0px 30px", width: "40%" }}
                                        onChange={(e: any) => handleVendorNameChange(e, index)}
                                        value={file.vendorName}></Input>
                                    </Flex>
                                  ))}
                                </Box>
                              }
                            </Box>
                          )}
                          {activeStep === 4 && (
                            <Box style={{ width: "100%", padding: "10px" }}>
                              {!!headers && Object.keys(headers)?.map((sheetName, index) => (
                                <Flex key={index} style={{ justifyContent: "space-between", width: "100%" }}>
                                  <Box paddingTop={"10px"}>
                                    Select those sheets you want sub-category assignment for:
                                    <Box style={{ display: "flex", padding: "7px", fontSize: "10px", width: "200px" }}>
                                      <Box style={{ display: "flex", lineHeight: "14px", height: "14px", width: "200px" }}>
                                        <Checkbox
                                          checked={headers[sheetName]?.subcategory}
                                          onChange={() => addSheetToCategory(sheetName)}
                                        />
                                        <Typography style={{ fontSize: "12px", width: "200px" }}> {sheetName}</Typography>
                                      </Box>
                                    </Box>
                                    {!!headers[sheetName]?.subcategory && <Box style={{ backgroundColor: colors.lightGray, padding: "10px", }}>
                                      Select columns to be used to for pivot:
                                      {headers[sheetName]?.vendor.map((col: string) => (
                                        <Box style={{ display: "flex", margin: "10px", padding: "5px", width: "200px" }}>
                                          <Checkbox
                                            checked={headers[sheetName]?.pivot?.includes(col)}
                                            onChange={() => addColToPivot(sheetName, col)}
                                          />
                                          <Typography style={{ fontSize: "12px" }}> {col}</Typography>
                                        </Box>
                                      ))}
                                    </Box>
                                    }
                                  </Box>
                                </Flex>
                              ))}
                            </Box>
                          )}
                        </Box>

                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                <StyledBox style={{ width: "100%" }} >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isLoading || activeStep > 4 || stepDisable()}
          >
            {activeStep === 4 ? "Create Comparison" : "Next"}
          </Button>
          {activeStep > 4 &&
                    <Typography> Please refresh page to get gsheet link</Typography>}
                </StyledBox>  
              </Box></>
            </Box>
          </Modal >
        </StyledBox >
      </StyledBox>
      <Box className={classes.tableContainer}>
        <>
          <Table>
            <TableHead>
          <TableRow>
                <TableCell align="center">Comparison Name</TableCell>
                <TableCell align="center">Google sheet link</TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
          {quotesTable?.length > 0 &&
                quotesTable.filter((q: any) => q.type !== "technical").map((row: any) => (
              <TableRow key={row?._id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={row?.boq?.url}
                    className={classes.link}
                  >
                    {row?.boq?.name}
                  </a>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={row?.result}
                    className={classes.link}
                  >
                    Link
                  </a>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => navigateToComparisonPage(row?._id)}
                    className={classes.link}
                  >
                    Go To Comparison
                  </a>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    margin: "40px",

  },
  tableHead: {
    backgroundColor: "#f5f5f5",
  },
  tableCellHead: {
    fontWeight: 600,
    padding: "12px",
    fontSize: "14px",
    color: "#333",
    textAlign: "center"
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
    },
  },
  tableCell: {
    padding: "10px",
    fontSize: "12px",
    color: "#555",
    textAlign: "center"
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default QuotationComparisonTool;
